<template>
  <b-row>
    <b-col>
      <b-card>
        <b-card-header>
          <b-card-title>
            Danh sách lịch sử yêu cầu
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <vue-good-table
              mode="remote"
              class="my-table"
              row-style-class="vgt-row"
              style-class="vgt-table striped bordered"
              :columns="columns"
              :rows="credentialProcessRequestDisplay"
              :line-numbers="true"
          >
            <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
            >
              Không có bản ghi nào !
            </div>
            <template
                slot="table-row"
                slot-scope="props"
            >
              <span v-if="props.column.field === 'status'">
                {{ getStatusName(props.row.status) }}
              </span>
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              <span
                  v-if="props.column.field === 'action' && props.row.status === 0"
                  class="thead-group-action"
              >
                <b-button-group>
                  <b-button
                      v-b-modal.credentialProcessHistorySaveModal
                      class="btn-icon"
                      variant="primary"
                      size="sm"
                      @click="onEditCredentialProcessRequest(props.row)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                      class="btn-icon"
                      variant="danger"
                      size="sm"
                      @click="onDeleteCredentialProcessRequest(props.row)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>

                </b-button-group>
              </span>
              <span
                  v-if="props.column.field === 'action' && props.row.status === -1"
                  class="thead-group-action"
              >
                <b-button-group>
                  <b-button
                      v-b-modal.messageDeny
                      class="btn-icon"
                      variant="primary"
                      size="sm"
                      @click="onShowMessage(props.row)"
                  >
                    <feather-icon icon="MessageSquareIcon" />
                  </b-button>
                </b-button-group>
              </span>
            </template>
          </vue-good-table>
        </b-card-body>
      </b-card>
      <CredentialProcessHistorySaveModal
          ref="credentialProcessHistorySaveModal"
          :item="currentCredentialProcessRequest"
          @succeed="onSucceed"
      />
      <MessageDeny
          ref="messageDeny"
          :credential-process-request-message="currentCredentialProcessRequest"
      />
    </b-col>
  </b-row>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import CredentialsOfStudent from '@/views/certificate/CredentialsOfStudent.vue'
import {
  BButton,
  BButtonGroup,
  BCard, BCardBody, BCardHeader, BCardTitle,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormSelect, BModal, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import CredentialProcessHistorySaveModal
  from '@/views/certificate/CredentialProcessHistory/CredentialProcessHistorySave.vue'
import MessageDeny from '@/views/certificate/CredentialProcessHistory/MessageProcess.vue'

export default {
  name: 'CredentialProcessHistory',
  directives: {
    Ripple,
  },
  components: {
    MessageDeny,
    CredentialProcessHistorySaveModal,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BRow,
    BModal,
    Ripple,
    vSelect,
  },
  props: {},
  data() {
    return {
      isLoading: false,
      user: getUser(),
      dataTest: [
        {
          name: 'test',
          credentialProcessType: 'test',
          credentialName: 'test',
          status: 0,
        },
        {
          name: 'test2',
          credentialProcessType: 'test2',
          credentialName: 'test2',
          status: 1,
        },
      ],
      currentCredentialProcessRequest: undefined,
      credentialProcessRequestDisplay: [],
      columns: [
        {
          label: 'Tên',
          field: 'studentName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
        },
        {
          label: 'Loại yêu cầu',
          field: 'certificateProcessTypeName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tên văn bằng, chứng chỉ',
          field: 'credentialName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'credentialProcessHistory/statuses',
      credentialProcessRequest: 'credentialProcessHistory/credentialProcessRequest',
    }),
  },
  async created() {
    await this.readCredentialProcessRequests({
      currentPage: 1,
      itemsPerPage: 1000,
      studentId: this.user.studentId,
    })
    this.credentialProcessRequestDisplay = this.credentialProcessRequest.map(request => {
      return {
        ...request,
        filesId: request.filesId ? request.filesId.split(',').map(Number) : [],
        filesName: request.filesName ? request.filesName.split(',').map(String) : [],

      }
    });
  },
  methods: {
    ...mapActions({
      readCredentialProcessRequests: 'credentialProcessHistory/readCredentialProcessRequests',
      deleteCredentialProcessRequest: 'credentialProcessHistory/deleteCredentialProcessRequest',
    }),

    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    onShowMessage(row) {
      this.currentCredentialProcessRequest = row
    },
    onEditCredentialProcessRequest(row) {
      this.currentCredentialProcessRequest = row
    },
    onDeleteCredentialProcessRequest(row) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xóa?',
        text: `Xóa ${row.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          const response = await this.deleteCredentialProcessRequest(row.id)
          const { isSuccessful, message } = response
          if (response) {
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              try {
                await this.readCredentialProcessRequests({
                  currentPage: 1,
                  itemsPerPage: 1000,
                  studentId: this.user.studentId,
                })
                this.credentialProcessRequestDisplay = this.credentialProcessRequest.map(request => {
                  return {
                    ...request,
                    filesId: request.filesId ? request.filesId.split(',').map(Number) : [],
                    filesName: request.filesName ? request.filesName.split(',').map(String) : [],
                  }
                })
              } catch (e) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `[${e.code}] ${e.message}`,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
          try {
            await this.deleteCredentialProcessRequest(row.id)
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async onSucceed() {
      await this.readCredentialProcessRequests({
        currentPage: 1,
        itemsPerPage: 1000,
        studentId: this.user.studentId,
      })
      this.credentialProcessRequestDisplay = this.credentialProcessRequest.map(request => {
        return {
          ...request,
          filesId: request.filesId ? request.filesId.split(',').map(Number) : [],
          filesName: request.filesName ? request.filesName.split(',').map(String) : [],
        }
      });
    }
  },
}
</script>


<style scoped lang="scss">

</style>
