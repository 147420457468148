<script>
import Ripple from 'vue-ripple-directive'
import { Flag } from '@/const/flag'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BModal, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { required } from '@validations'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'MessageDeny',
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    credentialProcessRequestMessage: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      targetItem: {
        description: '',
      },
    }
  },
  computed: {

  },
  methods: {
    ...mapActions({

    }),
    onShow() {
      if (this.credentialProcessRequestMessage) {
        this.targetItem.description = this.credentialProcessRequestMessage.description
      }
    },
    onHide() {
      this.targetItem = {
        description: '',
        id: '',
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<template>
  <b-modal
    id="messageDeny"
    body-class="position-static"
    centered
    :title="'Thông báo của cán bộ giải quyết'"
    no-close-on-backdrop
    @show="onShow"
    @hidden="onHide"
  >
    <b-form>
      <b-form-group label-for="description">
        <template v-slot:label>

          <b-form-textarea
            id="description"
            v-model="targetItem.description"
            readonly
            rows="5"
            max-rows="9"
            aria-disabled="true"
            placeholder="Thông báo của cán bộ xử lý..."
          />
        </template>
      </b-form-group>
    </b-form>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          @click="$bvModal.hide('messageDeny')"
        >
          <span class="text-right">OK
          </span>

        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped lang="scss">

</style>
