<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="credentialProcessHistorySaveModal"
        body-class="position-static"
        centered
        :title="'Cập nhật yêu cầu'"
        no-close-on-backdrop
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-form-group>
          <template v-slot:label>
            Mô tả yêu cầu<span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors}"
              name="Mô tả yêu cầu"
              rules="required"
          >
            <b-form-input
                id="name"
                v-model="targetItem.name"
                placeholder="Mô tả yêu cầu"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
            v-for="(item, index) in item.filesName"
            :key="index"
            ref="row"
        >
          <template v-slot:label>
            File yêu cầu {{ index + 1 }}
          </template>
          <b-form-file
              ref="inputFileRef"
              :placeholder="item"
              @change="event => uploadFile(event, index)"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('credentialProcessHistorySaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard, BCardBody, BCardHeader, BCardTitle,
  BCol,
  BContainer,
  BDropdownItem, BForm, BFormFile,
  BFormGroup, BFormInput,
  BFormSelect,
  BModal, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'CredentialProcessHistorySaveModal',
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BRow,
    BFormInput,
    BFormFile,
    BForm,
    Ripple,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      targetItem: {
        name: '',
        filesId: [],
      },
    }
  },
  computed: {
    ...mapGetters({

    }),
  },
  methods: {
    ...mapActions({
      uploadSample: 'credentialProcessHistory/uploadSample',
      updateCredentialProcessRequest: 'credentialProcessHistory/updateCredentialProcessRequest',
    }),
    onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      }
    },
    onHide() {
      this.targetItem = {
        name: '',
        filesId: [],
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    async uploadFile(e, index) {
      this.isLoading = true
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File tài liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        this.isLoading = false
        return
      }

      files.forEach(file => formData.append('files', file))

      this.mediaIds = await this.uploadSample(formData)

      if (this.mediaIds.length > 0 && this.targetItem.filesId.length === 0) {
        this.targetItem.filesId.push(this.mediaIds[0])
        this.showToast('Upload file thành công', 'CheckIcon', 'success')
      } else if (this.mediaIds.length > 0 && this.targetItem.filesId.length > 0){
        this.targetItem.filesId[index] = this.mediaIds[0]
        this.showToast('Upload file thành công', 'CheckIcon', 'success')
      }else{
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      this.isLoading = false
    },
    async onSave(type = null){
      const valid = this.$refs
          .saveFormRef
          .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.updateCredentialProcessRequest({
            id: this.item.id,
            params: this.targetItem,
          })
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('credentialProcessHistorySaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">

</style>
